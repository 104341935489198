import React, { useCallback, useState, useEffect } from 'react';
import { Router } from '@reach/router';

import { useLoads } from 'react-loads';

import { sanityClient } from '../../lib/sanityClient';

import HomePage from '../../pages/index';
import ModularTemplate from '../../templates/page';
import PostTemplate from '../../templates/post';

const PreviewPage = ({ document }) => {
  const queryDraft = `*[_id == "${document}"]{
    ...,
    modules{
      modules[]{
        ...,
        _type == 'bioGrid' => {
          ...,
          items[]->{
            ...,
          }
        },
        _type == 'careersFeed' => {
          ...,
          items[]->{
            ...
          }
        },
        _type == 'newsCarousel' => {
          ...,
          selectedPosts[]->{
            ...
          }
        },
      }
    }
  }`;

  const [doc, setDoc] = useState();

  const handlePreviewFetch = useCallback(
    () =>
      sanityClient.fetch(queryDraft).then((response) => {
        setDoc(response[0]);
      }),
    []
  );

  const { error, isResolved, isPending, isReloading, load } = useLoads(
    'handlePreviewFetch',
    handlePreviewFetch,
    {
      defer: true,
    }
  );

  useEffect(() => {
    load();
    const subscription = sanityClient.listen(queryDraft).subscribe((update) => {
      setDoc(update && update.result);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [0]);

  const renderPreview = () => {
    if (doc) {
      switch (doc._type) {
        case 'homePage':
          return <HomePage previewDoc={doc} preview={true} />;
        case 'modularPage':
          return <ModularTemplate previewDoc={doc} preview={true} />;
        case 'blogPost':
          return <PostTemplate previewDoc={doc} preview={true} />;
        default:
          break;
      }
    }
  };

  return (
    <>
      {(isPending || isReloading) && (
        <div className="ac">
          <span>Loading Preview</span>
        </div>
      )}
      {isResolved && !isPending && renderPreview()}
    </>
  );
};

const Preview = () => {
  return (
    <Router>
      <PreviewPage path="/preview/:document" />
    </Router>
  );
};

export default Preview;
