import React from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';

import Layout from '@components/layout';
import BlogBanner from '@components/blog/BlogBanner';
import SimplePortableText from '@components/sanity/simplePortableText';
import PostsList from '@components/blog/PostsList';

const PostTemplate = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;

  const relatedPosts = data?.relatedPosts;

  const { seo, title, postImage, category, post } = page;

  return (
    <Layout>
      <PageMeta {...seo} />
      <BlogBanner title={title} category={category} postImage={postImage} />
      <div className="px-gutter py-32">
        <SimplePortableText text={post} className="max-w-7xl mx-auto" />
      </div>
      <div className="bg-secondary-25 py-16 lg:py-32">
        <h3 className="px-gutter lg:text-center">More articles</h3>
        <PostsList posts={relatedPosts?.nodes} />
      </div>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query standardPostQuery($slug: String!, $postDate: Date!) {
    page: sanityBlogPost(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      category {
        title
        slug {
          current
        }
      }
      post: _rawPost(resolveReferences: { maxDepth: 10 })
      postImage {
        ...ImageWithPreview
      }
    }
    relatedPosts: allSanityBlogPost(
      sort: { order: DESC, fields: postDate }
      limit: 2
      filter: { postDate: { gte: $postDate } }
    ) {
      nodes {
        id
        title
        shortDescription
        postImage {
          ...ImageWithPreview
        }
        postDate: postDate(formatString: "MMMM-DD-YYYY")
        category {
          _id
          title
          slug {
            current
          }
        }
        slug {
          current
        }
      }
    }
  }
`;
