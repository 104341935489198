import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import { SanityImage } from '@components/sanity/sanityImage';

const PostCard = ({ slug, title, shortDescription, postImage, postDate, isCarousel, ...props }) => {
  return (
    <Link to={`/news/${slug?.current}`} {...props}>
      <div className="aspect-w-5 aspect-h-3 relative rounded-xl overflow-hidden">
        <SanityImage
          image={postImage}
          className="object-cover absolute w-full h-full top-0 left-0"
        />
      </div>
      <div
        className={cx('grid gap-2 pt-6', {
          'p-6 lg:p-8': isCarousel,
        })}
      >
        <h6 className="text-small text-primary">{postDate}</h6>
        <h5>{title}</h5>
        <p>{shortDescription}</p>
        <span className="text-secondary font-bold text-small">Read full story</span>
      </div>
    </Link>
  );
};

export default PostCard;
