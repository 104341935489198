import React from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import Module from '@components/pageModules';
import Layout from '@components/layout';

const NEW_FEATURES_ENABLED = process.env.GATSBY_NEW_FEATURES_ENABLED === '1';

const PageTemplate = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;

  const { modules: pageModules, seo } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      {pageModules?.modules?.map((item) => (
        <Module
          key={item?.key}
          module={item}
          newFeaturesEnabled={preview ? true : NEW_FEATURES_ENABLED}
        />
      ))}
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityModularPage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      modules {
        ...PageModules
      }
    }
  }
`;
