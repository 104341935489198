import React, { useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import { SplitText } from 'gsap/SplitText';
import { gsap } from 'gsap';

import { SanityImage } from '@components/sanity/sanityImage';
import HouseIcon from '@svg/house.svg';
import ArrowR from '@svg/chevron-r.svg';

const BlogBanner = ({ title, category, postImage }) => {
  const headlineRef = useRef(null);
  const backgroundWrapper = useRef(null);
  const uiRef = useRef(null);
  const timelineRef = useRef(
    gsap.timeline({
      paused: true,
    })
  );

  useEffect(() => {
    const lineChildren = new SplitText(headlineRef.current, {
      type: 'lines',
    });
    new SplitText(headlineRef.current, {
      type: 'lines',
      linesClass: ' overflow-hidden',
    });

    timelineRef.current
      .to(backgroundWrapper.current, {
        duration: 3,
        scale: 1.1,
      })
      .from(
        lineChildren.lines,
        {
          duration: 1.5,
          yPercent: 100,
          stagger: 0.1,
          ease: 'power4.out',
        },
        0.2
      )
      .from(
        uiRef.current,
        {
          opacity: 0,
          duration: 1.5,
        },
        0.8
      );

    timelineRef.current.play();
  }, []);

  return (
    <div className="text-white flex relative overflow-hidden w-full bg-black min-h-[100vh] lg:min-h-[740px]">
      <div className="w-full h-full absolute top-0 left-0 " ref={backgroundWrapper}>
        <SanityImage
          image={postImage}
          className="absolute top-0 left-0 object-cover w-full h-full opacity-40 z-0"
        />
      </div>
      <div className="px-gutter flex flex-col w-full justify-end py-16 gap-4 relative z-10">
        <h1 className="max-w-3xl" ref={headlineRef}>
          {title}
        </h1>

        <div className="w-full border-t py-10" ref={uiRef}>
          <div className="flex gap-6 text-white items-center">
            <Link to="/">
              <HouseIcon className="w-4" />
            </Link>
            <ArrowR className="fill-current w-[5px]" />
            <Link to="/news">News</Link>
            <ArrowR className="fill-current w-[5px]" />
            <Link to="/news">{category?.title}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogBanner;
